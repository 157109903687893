// StrictMode not used: breaks drag and drop (at least in development mode)
import { useState, useCallback, useMemo, useRef, useEffect, Suspense, lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import { CssBaseline, Container, Box, Typography } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { nlNL } from '@mui/material/locale';
import CookieConsent from 'react-cookie-consent';
import NavMenu from './components/NavMenu';
import Login from './components/Login';
import { Snackbar, useSnackbar } from './components/helper/MessageBanner';
import { useSessionStorage } from './components/helper/useSessionStorage';
import * as services from './controllers/services';

const TaskList = lazy(() => import('./components/TaskList'))
const GridSetup = lazy(() => import('./components/GridSetup'))
const ScanPage = lazy(() => import('./components/ScanPage'))
const OtpSetup = lazy(() => import('./components/unauth/OtpSetup'));
const ForgotPassword = lazy(() => import('./components/unauth/ForgotPassword'));
const Profile = lazy(() => import('./components/Profile'));

const PageLoad = () => <Typography component='em'>Pagina laden...</Typography>;

const AddTitle = ({ elm, title }) => {
    useEffect(() => { document.title = title ? `Etiketopdracht - ${title}` : 'Etiketopdracht'; });
    return elm;
}

export default function App() {
    const [appUser, setAppUser, appUserRead] = useSessionStorage('appUser', { isLoggedIn: false });
    const setAppUserLoggedOff = useCallback(() => { setAppUser(prev => { return { ...prev, isLoggedIn: false } }); }, [setAppUser]);
    const [phase, setPhase] = useSessionStorage('phase');
    const [showComponents, setShowComponents] = useSessionStorage('subtotals');
    const [showToolbar, setShowToolbar] = useSessionStorage('toolbar');
    const [lastRefresh, setLastRefresh] = useState();
    const [forceRefresh, setForceRefresh] = useState(0);
    const [gridSetupModified, setGridSetupModified] = useState(false);
    const [isTalking, setTalking] = useState(false);
    const [snackbarOptions, snackbar] = useSnackbar(setAppUserLoggedOff);
    const headerRef = useRef();

    const doLogoff = useCallback(() => {
        snackbar.remove();
        services.logout(setTalking)
            .then((data) => { snackbar.showInfo(data.message, 2000); })
            .catch((e) => { snackbar.handleError(e); })
            .finally(() => { setAppUserLoggedOff(); });
    }, [snackbar, setAppUserLoggedOff]);

    const theme = createTheme(
        {
            palette: {
                background: {
                    default: "#fafafa"
                },
                primary: {
                    main: '#E2057C'
                },
                secondary: {
                    main: '#edebec',
                    dark: '#333',
                },
                text: {
                    primary: '#333',
                },
            },
        },
        nlNL
    );
    
    const globalProps = useMemo(() => { return { appUser, phase, isTalking, setTalking, snackbar } }, [appUser, phase, isTalking, setTalking, snackbar]);

    const loginFallback = useCallback((elm, title) => {
        if (!elm || !globalProps.appUser.isLoggedIn)
            return <AddTitle elm={<Login {...globalProps} setAppUser={setAppUser} setPhase={setPhase} setShowComponents={setShowComponents} setShowToolbar={setShowToolbar} />} title={title} />;
        return <AddTitle elm={elm} title={title} />;
    }, [globalProps, setAppUser, setPhase, setShowComponents, setShowToolbar]);


    if (!appUserRead)
        return <PageLoad />;

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Box ref={headerRef}>
                <NavMenu {...globalProps} setPhase={setPhase} setForceRefresh={setForceRefresh} lastRefresh={lastRefresh} doLogoff={doLogoff} gridSetupModified={gridSetupModified} />
            </Box>
            <Container maxWidth={false}>
                <Suspense fallback={<PageLoad />}>
                    <Routes>
                        <Route
                            path='/'
                            element={loginFallback()} />
                        <Route
                            path='/tasklist'
                            element={loginFallback(
                                <TaskList
                                    {...globalProps}
                                    showComponents={showComponents}
                                    setShowComponents={setShowComponents}
                                    showToolbar={showToolbar}
                                    setShowToolbar={setShowToolbar}
                                    setLastRefresh={setLastRefresh}
                                    forceRefresh={forceRefresh}
                                    headerHeight={headerRef.current && headerRef.current.offsetHeight}
                                />, 'taaklijst')}
                        />
                        <Route
                            path='/gridsetup'
                            element={loginFallback(
                                <GridSetup
                                    {...globalProps}
                                    headerHeight={headerRef.current && headerRef.current.offsetHeight}
                                    setGridSetupModified={setGridSetupModified}
                                    showComponents={showComponents}
                                    setShowComponents={setShowComponents}
                                    showToolbar={showToolbar}
                                    setShowToolbar={setShowToolbar}
                                />, 'instellingen')}
                        />
                        <Route
                            path='/scan'
                            element={loginFallback(<ScanPage {...globalProps} />, 'barcode scannen')} />
                        <Route
                            path='/otpsetup'
                            element={<AddTitle elm={<OtpSetup {...globalProps} />} title='2FA instellen' />} />
                        <Route
                            path='/forgotpassword'
                            element={<AddTitle elm={<ForgotPassword {...globalProps} />} title='wachtwoord vergeten' />} />
                        <Route
                            path='/profile'
                            element={loginFallback(<Profile {...globalProps} doLogoff={doLogoff} />, 'profiel')} />
                        <Route
                            path='/*'
                            element={loginFallback()} />
                    </Routes>
                </Suspense>
                <Snackbar options={snackbarOptions} remove={snackbar.remove} />
            </Container>
            <CookieConsent buttonText='Ik begrijp het' overlay style={{ padding: theme.spacing(1, 0), alignItems: 'center', }} buttonStyle={{ padding: theme.spacing(1, 3), background: theme.palette.primary.main, color: 'white', fontWeight: '600', borderRadius: '4px', }}>
                <Typography variant='body2' >De website maakt gebruik van cookies om te functioneren</Typography>
            </CookieConsent>
        </ThemeProvider>
    );
}
