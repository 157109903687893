import { useCallback } from 'react';

export function useDebounce() {
    const debounce = useCallback((fn, ms) => {
        let timer;
        return () => {
            clearTimeout(timer);
            timer = setTimeout(() => {
                timer = null;
                fn.apply(this, arguments);
            }, ms);
        }
    }, []);

    return debounce;
}
