import { forwardRef } from 'react';
import { Box, FormControl, Input, InputLabel, FormHelperText, ButtonBase } from '@mui/material';
import PropTypes from 'prop-types';

export const TextInput = forwardRef(
    function TextInput({ required, disabled, error, hiddenLabel, fullWidth, margin = 'none', size = 'medium', label, sx = [], helperText, id, ...other }, ref) {
        const defaultSx = fullWidth ? { my: 1, bgcolor: 'common.white' } : { m: 1, width: '20ch', bgcolor: 'common.white' };
        if (hiddenLabel) {
            other['aria-label'] = label;
        }
        return (
            <FormControl sx={[defaultSx, ...(Array.isArray(sx) ? sx : [sx])]} variant="standard" required={required} disabled={disabled} error={error} hiddenLabel={hiddenLabel} margin={margin} size={size} fullWidth={fullWidth}>
                {!hiddenLabel &&
                    <InputLabel htmlFor={id}>{label}</InputLabel>}
                <Input inputRef={ref} id={id} {...other} />
                {helperText &&
                    <FormHelperText>{helperText}</FormHelperText>}
            </FormControl>
        )
    });
TextInput.propTypes = {
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    hiddenLabel: PropTypes.bool,
    fullWidth: PropTypes.bool,
    margin: PropTypes.oneOf(['dense', 'normal', 'none']),
    size: PropTypes.oneOf(['small', 'medium']),
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
    type: PropTypes.string.isRequired,
    label: PropTypes.string,
    value: PropTypes.any.isRequired,
    onChange: PropTypes.func.isRequired,
    sx: PropTypes.object,
    helperText: PropTypes.string,
}

export const Button = ({ fullWidth, sx = [], ...other }) => {
    const marginWidthSx = fullWidth ? { my: 1, width: '100%' } : { m: 1 };
    return <ButtonBase {...other} focusRipple sx={[marginWidthSx, {
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        boxSizing: 'border-box',
        textDecoration: 'none',
        typography: 'button',
        backgroundColor: 'primary.main',
        minWidth: '75px',
        padding: '6px 16px',
        borderRadius: 1,
        color: 'primary.contrastText',
        transition: (theme) => theme.transitions.create(['background-color', 'box-shadow', 'border-color', 'color'], {
            duration: theme.transitions.duration.short
        }),
        cursor: 'pointer',
        outline: 0,
        border: 0,
        userSelect: 'none',
        verticalAlign: 'middle',
        boxShadow: 2,
        '&:hover': {
            backgroundColor: 'primary.dark',
            boxShadow: 4
        },
        '&:active': {
            boxShadow: 8
        },
        '&:disabled': {
            pointerEvents: 'none',
            cursor: 'default',
            boxShadow: 'none',
            color: 'action.disabled',
            backgroundColor: 'action.disabledBackground'
        }
    }, ...(Array.isArray(sx) ? sx : [sx])]} />
}
Button.propTypes = {
    fullWidth: PropTypes.bool,
    sx: PropTypes.object,
    disabled: PropTypes.bool,
    type: PropTypes.oneOf(['submit']),
    onClick: PropTypes.func,
    component: PropTypes.elementType,
}

export const RenderMultiLine = ({ value }) => {
    const lines = new String(value).split('<br/>');
    if (lines.length === 1) {
        return <div className='MuiDataGrid-cellContent'>{value}</div>
    }
    return (
        <Box width='100%'>
            {lines.map((option) =>
                <Box key={option} className='MuiDataGrid-cellContent'>
                    {option}
                </Box>)}
        </Box>
    );
}
RenderMultiLine.propTypes = {
    value: PropTypes.string
}