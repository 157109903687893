import { useMemo } from 'react';

export function usePasswordStrength(password) {
    const passwordTracker = useMemo(() => {
        const atLeastOneUppercase = /[A-Z]/g; // capital letters from A to Z
        const atLeastOneLowercase = /[a-z]/g; // small letters from a to z
        const atLeastOneNumeric = /[0-9]/g; // numbers from 0 to 9
        const atLeastOneSpecialChar = /[#?!@$%^&*-]/g; // any of the special characters within the square brackets
        const eightCharsOrMore = /.{8,}/g; // eight characters or more
        return {
            uppercase: password.match(atLeastOneUppercase),
            lowercase: password.match(atLeastOneLowercase),
            number: password.match(atLeastOneNumeric),
            specialChar: password.match(atLeastOneSpecialChar),
            eightCharsOrGreater: password.match(eightCharsOrMore),
        }
    }, [password]);

    const passwordStrength = useMemo(() =>
        Object.values(passwordTracker).filter((value) => value).length, [passwordTracker]);

    const invalidPasswordText = useMemo(() => {
        let n = '',
            reasonAdded = false,
            addReason = (s, reason) => {
                n = reasonAdded ? s += ', ' + reason : s += reason;
                reasonAdded = true;
                return n;
            };
        if (passwordStrength < 5) {
            n = 'Bevat geen ';
            if (!passwordTracker.uppercase)
                n = addReason(n, 'hoofdletter');
            if (!passwordTracker.lowercase)
                n = addReason(n, 'kleine letter');
            if (!passwordTracker.specialChar)
                n = addReason(n, 'speciaal teken');
            if (!passwordTracker.number)
                n = addReason(n, 'cijfer');
            if (!passwordTracker.eightCharsOrGreater)
                n = addReason(n, 'acht of meer tekens');
        }
        return n;
    }, [passwordStrength, passwordTracker]);

    return [passwordStrength, invalidPasswordText];
}
