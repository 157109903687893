import { useState, useEffect } from 'react';

export function useSessionStorage(key, initialValue) {
    const [value, setValue] = useState(initialValue);
    const [readHasRun, setReadHasRun] = useState(false);

    useEffect(() => {
        try {
            const storageValue = JSON.parse(sessionStorage.getItem(key));
            if (storageValue) {
                setValue(storageValue);
            }
            setReadHasRun(true);
        } catch (error) {
            console.warn(`Error reading sessionStorage key “${key}”:`, error);
        }
    }, [key]);

    useEffect(() => {
        try {
            if (value !== undefined)
                sessionStorage.setItem(key, JSON.stringify(value));
        } catch (error) {
            console.warn(`Error setting sessionStorage key “${key}”:`, error);
        }
    }, [key, value]);

    return [value, setValue, readHasRun];
}
