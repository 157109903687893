import { useState, useEffect, useCallback } from 'react';

export function useTimeRemaining() {
    const [seconds, setSeconds] = useState(0);
    const [timerID, setTimerID] = useState(undefined);

    useEffect(() => {
        return () => { clearInterval(timerID); };
    }, [timerID]);

    const setTimeRemaining = useCallback((seconds) => {
        setSeconds(seconds);
        setTimerID(seconds === 0 ? undefined : setInterval(
            () => {
                setSeconds(prevSeconds => Math.max(prevSeconds - 1, 0));
            }, 1000));
    }, []);

    return [seconds, setTimeRemaining];
}