import { useState, useCallback } from 'react';
import { Link as RouterLink, useLocation, useNavigate, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Grid, Typography } from '@mui/material';
import { TextInput, Button } from './helper/Controls';
import { FlexColumnPaper } from './helper/MessageBanner';
import { useTimeRemaining } from './helper/useTimeRemaining';
import { usePasswordStrength } from './helper/usePasswordStrength';
import * as services from '../controllers/services';

export default function Login({ appUser, setAppUser, isTalking, setTalking, setPhase, setShowComponents, setShowToolbar, snackbar }) {
    const [user, setUser] = useState('');
    const [password, setPassword] = useState('');
    const [otpToken, setOtpToken] = useState('');
    const [otpMethod, setOtpMethod] = useState('');
    const [startVerify, setStartVerify] = useState(false);
    const [newPasswordRequired, setNewPasswordRequired] = useState(false);
    const [timeRemaining, setTimeRemaining] = useTimeRemaining();
    const [signupPassword, setSignupPassword] = useState('');
    const [signupPasswordAgain, setSignupPasswordAgain] = useState('');
    const [passwordStrength, invalidPasswordText] = usePasswordStrength(signupPassword);
    let location = useLocation();
    let navigate = useNavigate();

    const finishLogin = useCallback((data) => {
        setPhase(data.phase);
        delete data.phase;
        setShowComponents(data.subtotalsVisible);
        delete data.subtotalsVisible;
        setShowToolbar(data.toolbarVisible);
        delete data.toolbarVisible;
        delete data.sessionHasToken;
        delete data.newPasswordRequired;
        setAppUser({ isLoggedIn: true, ...data });
    }, [setPhase, setShowComponents, setShowToolbar, setAppUser]);

    const onClickSend = useCallback(() => {
        snackbar.remove();
        services.sendToken(user, password, setTalking)
            .then(data => {
                setTimeRemaining(data.timeRemaining);
                snackbar.showInfo(data.message);
            })
            .catch(e => { snackbar.handleError(e); });
    }, [user, password, setTalking, setTimeRemaining, snackbar]);

    const doLogin = useCallback((user, password) => {
        services.login(user, password, setTalking)
            .then(data => {
                if (data.newPasswordRequired) {
                    setNewPasswordRequired(true);
                } else {
                    if (data.sessionHasToken) {
                        finishLogin(data);
                    } else {
                        if (!data.otpEnabled) {
                            navigate('/otpsetup', {
                                state: {
                                    employee: user,
                                    msgOnFinish: 'U kunt nu inloggen met de gekozen 2FA-methode.'
                                },
                                replace: true
                            });
                        } else {
                            setStartVerify(true);
                            setOtpMethod(data.otpMethod);
                            if (data.otpMethod === 'mail') {
                                onClickSend();
                            }
                        }
                    }
                }
            })
            .catch(e => {
                snackbar.handleError(e);
                setUser('');
                setPassword('');
            });
    }, [setTalking, finishLogin, snackbar, navigate, onClickSend]);

    const onSubmitLogin = useCallback((e) => {
        e.preventDefault();
        snackbar.remove();
        doLogin(user, password);
    }, [user, password, doLogin, snackbar]);

    const onSubmitVerify = useCallback((e) => {
        e.preventDefault();
        snackbar.remove();
        services.verify(user, password, otpToken, otpMethod, setTalking)
            .then(data => { finishLogin(data); })
            .catch(e => {
                snackbar.handleError(e);
                setOtpToken('');
            });
    }, [user, password, otpToken, otpMethod, setTalking, finishLogin, snackbar]);

    const onSubmitChangePassword = useCallback((e) => {
        e.preventDefault();
        snackbar.remove();
        services.changePasswordOnLogin(user, password, signupPassword, setTalking)
            .then(data => {
                setNewPasswordRequired(false);
                snackbar.showInfo(data.message);
                setPassword(signupPassword);
                doLogin(user, signupPassword);
            })
            .catch(e => {
                snackbar.handleError(e);
                setSignupPassword('');
                setSignupPasswordAgain('');
            });
    }, [user, password, signupPassword, doLogin, setTalking, snackbar]);

    if (appUser.isLoggedIn)
        return (
            <Navigate to={location.pathname === '/' ? '/tasklist' : '/profile'} replace={true} />
        );

    if (newPasswordRequired)
        return (
            <FlexColumnPaper title='Wachtwoord wijzigen'>
                <Box component='form' onSubmit={onSubmitChangePassword}>
                    <Typography variant='body2' sx={{ mt: 1, mb: 2, }}>Geef een nieuw wachtwoord op.</Typography>
                    <TextInput required fullWidth id="signupPassword" type="password" label="Wachtwoord" value={signupPassword} onChange={(e) => { setSignupPassword(e.target.value) }} disabled={isTalking}
                        helperText={signupPassword !== '' ? invalidPasswordText : ''}
                        error={passwordStrength < 5 && signupPassword !== ''} />
                    <TextInput required fullWidth id="signupPasswordAgain" type="password" label="Wachtwoord (nogmaals)" value={signupPasswordAgain} onChange={(e) => { setSignupPasswordAgain(e.target.value) }} disabled={isTalking}
                        helperText={signupPassword !== signupPasswordAgain && signupPasswordAgain !== '' ? 'Wachtwoorden zijn niet gelijk' : ''}
                        error={signupPassword !== signupPasswordAgain && signupPasswordAgain !== ''} />
                    <Button type="submit" fullWidth disabled={!user || !signupPassword || !signupPasswordAgain || signupPassword !== signupPasswordAgain || passwordStrength < 5 || isTalking} sx={{ mt: 2, mb: 2 }}>Wijzigen</Button>
                </Box>
            </FlexColumnPaper>
        );

    if (startVerify)
        return (
            <FlexColumnPaper title='Code invoeren'>
                <Box component='form' onSubmit={onSubmitVerify}>
                    {(otpMethod === 'mail') && (
                        <Typography variant='body2' sx={{ mt: 1, mb: 1, }}>Voer de verificatiecode in die je per mail ontvangen hebt</Typography>
                    )}
                    {(otpMethod === 'auth') && (
                        <Typography variant='body2' sx={{ mt: 1, mb: 2, }}>Voer de verificatiecode in die je met de Authenticator (app) kunt opvragen</Typography>
                    )}
                    <TextInput required fullWidth autoFocus autoComplete='one-time-code' id="otpToken" type="text" inputMode="numeric" label="Verificatiecode" value={otpToken} onChange={(e) => { setOtpToken(e.target.value) }} disabled={isTalking} />
                    <Button type="submit" fullWidth disabled={!otpToken || isTalking || (otpMethod === 'mail' && timeRemaining <= 0)} sx={{ mt: 2, mb: 2 }}>Verifi&euml;ren</Button>
                    {otpMethod === 'mail' && timeRemaining > 0 &&
                        <Grid container>
                            <Grid item xs>
                                <Typography variant='body2' sx={{ color: 'red', }}>{`nog ${timeRemaining} seconden geldig`}</Typography>
                            </Grid>
                            <Grid item />
                        </Grid>}
                    {otpMethod === 'mail' && timeRemaining <= 0 &&
                        <Button onClick={onClickSend} fullWidth disabled={isTalking}>E-mail nieuwe code</Button>}
                </Box>
            </FlexColumnPaper>
        );

    return (
        <FlexColumnPaper title='Welkom'>
            <Box component='form' onSubmit={onSubmitLogin}>
                <TextInput required fullWidth autoFocus id="user" type="text" label="Gebruikersnaam" value={user} onChange={(e) => { setUser(e.target.value) }} disabled={isTalking} />
                <TextInput required fullWidth id="password" type="password" label="Wachtwoord" value={password} onChange={(e) => { setPassword(e.target.value) }} disabled={isTalking} />
                <Button type="submit" fullWidth disabled={!user || !password || isTalking} sx={{ mt: 2, mb: 2 }}>Inloggen</Button>
            </Box>
            <Grid container justifyContent='center'>
                <Grid item>
                    <Typography variant='body2' component={RouterLink} to='/forgotpassword' sx={{ textDecoration: 'none', color: 'grey', }}>Wachtwoord vergeten?</Typography>
                </Grid>
            </Grid>
        </FlexColumnPaper>
    );
}
Login.propTypes = {
    appUser: PropTypes.exact({
        user: PropTypes.string,
        name: PropTypes.string,
        isLoggedIn: PropTypes.bool,
        vendor: PropTypes.string,
        vendorName: PropTypes.string,
        phaseOptions: PropTypes.arrayOf(PropTypes.string)
    }),
    setAppUser: PropTypes.func,
    isTalking: PropTypes.bool,
    setTalking: PropTypes.func,
    setPhase: PropTypes.func,
    setShowComponents: PropTypes.func,
    setShowToolbar: PropTypes.func,
    snackbar: PropTypes.exact({
        showSuccess: PropTypes.func,
        showInfo: PropTypes.func,
        handleError: PropTypes.func,
        remove: PropTypes.func
    })
}
