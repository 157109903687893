import { useCallback, useState, useMemo } from 'react';
import { Alert, Typography, Snackbar as MuiSnackbar, Grid, Paper, Box } from '@mui/material';
import PropTypes from 'prop-types';

export function Snackbar({ options, remove }) {
    const handleClose = useCallback((e, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        remove();
    }, [remove]);

    return (
        <MuiSnackbar
            key={options.value}
            open={options.open}
            autoHideDuration={options.autoHideDuration}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
            <Alert onClose={handleClose} severity={options.severity} variant='filled' sx={{ width: '100%' }}>
                {options.value}
            </Alert>
        </MuiSnackbar>
    );
}
Snackbar.propTypes = {
    options: PropTypes.exact({
        open: PropTypes.bool.isRequired,
        severity: PropTypes.oneOf(['error', 'warning', 'info', 'success']),
        value: PropTypes.string,
        autoHideDuration: PropTypes.number
    }).isRequired,
    remove: PropTypes.func.isRequired
}

export function useSnackbar(setAppUserLoggedOff) {
    const [snackbarOptions, setSnackbarOptions] = useState({ open: false });
    const snackbar = useMemo(() => {
        function showSuccess(msg, ms) {
            setSnackbarOptions({
                open: true,
                severity: 'success',
                value: msg,
                autoHideDuration: ms ?? 2000
            });
        }

        function showInfo(msg, ms) {
            setSnackbarOptions({
                open: true,
                severity: 'info',
                value: msg,
                autoHideDuration: ms ?? 5000
            });
        }

        function handleError(error, ms) {
            if (error.unauthorized)
                setAppUserLoggedOff()
            else if (!error.canceled)
                setSnackbarOptions({
                    open: true,
                    severity: 'error',
                    value: error.message,
                    autoHideDuration: ms ?? 8000
                });
        }

        function remove() {
            setSnackbarOptions(prevMsg => { return { ...prevMsg, open: false } });
        }

        return { showSuccess, showInfo, handleError, remove }
    }, [setAppUserLoggedOff]);

    return [snackbarOptions, snackbar];
}

export const FlexColumnPaper = ({ title, alignItems, sm, md, children }) =>
    <Grid component='main' container spacing={2} sx={{ minHeight: '60vh', }} direction='row' justifyContent='center' alignContent='center' >
        <Grid item xxs={12} sm={sm} md={md}>
            <Paper>
                <Box sx={{ p: 3, display: 'flex', flexDirection: 'column', alignItems: alignItems, minHeight: '375px', }}>
                    {title && <Typography variant='h5' color='secondary.dark' sx={{ mb: 1, }}>{title}</Typography>}
                    {children}
                </Box>
            </Paper>
        </Grid>
    </Grid>;
FlexColumnPaper.propTypes = {
    title: PropTypes.string,
    alignItems: PropTypes.oneOf(['normal', 'center']),
    sm: PropTypes.number,
    md: PropTypes.number,
    children: PropTypes.node
}
FlexColumnPaper.defaultProps = {
    alignItems: 'center',
    sm: 6,
    md: 4
}
