let controller, sourceUrl;

function tryGetMessage(err) {
    if (err.error)
        if (err.error.message)
            return err.error.message;
    if (err.message)
        return err.message;
    return 'onbekende fout';
}

const callPost = (url, setTalking, data) => {
    return new Promise((resolve, reject) => {
        data.webSessionId = sessionStorage.getItem('sessionId');
        const config = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        }
        config.body = JSON.stringify(data);

        setTalking(true);
        sourceUrl = url;
        controller = new AbortController();
        config.signal = controller.signal;
        fetch(url, config)
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
                return response.json().then((jsonError) => {
                    throw {
                        message: tryGetMessage(jsonError),
                        status: response.status
                    };
                });
            })
            .then((jsonResponse) => {
                if (jsonResponse.webSessionId) {
                    sessionStorage.setItem('sessionId', jsonResponse.webSessionId);
                    delete jsonResponse.webSessionId;
                }
                setTalking(false);
                resolve(jsonResponse);
            })
            .catch((error) => {
                setTalking(false);
                reject({
                    message: error.message,
                    unauthorized: error.status === 401,
                    canceled: error.name === 'AbortError'
                });
            });
    });
}

const cancelRequest = (url) => {
    if (url === sourceUrl)
        controller.abort();
}

export const login = (user, password, setTalking) =>
    callPost('/api/Login', setTalking, { user, password });

export const sendToken = (user, password, setTalking) =>
    callPost('/api/SendToken', setTalking, { user, password });

export const verify = (user, password, otpToken, otpMethod, setTalking) =>
    callPost('/api/Verify', setTalking, { user, password, otpToken, otpMethod });

export const logout = (setTalking) =>
    callPost('/api/auth/Logout', setTalking, {});

export const changePassword = (oldPassword, newPassword, setTalking) =>
    callPost('/api/auth/ChangePassword', setTalking, { oldPassword, newPassword });

export const resetOtp = (setTalking) =>
    callPost('/api/auth/ResetOtp', setTalking, {});

export const getBatchLines = (phase, showSelection, setTalking) =>
    callPost('/api/auth/GetBatchLines', setTalking, { phase, showSelection });
export const cancelGetBatchLines = () =>
    cancelRequest('/api/auth/GetBatchLines');

export const getProductLines = (batchNo, setTalking) =>
    callPost('/api/auth/GetProductLines', setTalking, { batchNo });

export const setDensity = (density, setTalking) =>
    callPost('/api/auth/SetDensity', setTalking, { density });

export const setPageSize = (pageSize, setTalking) =>
    callPost('/api/auth/SetPageSize', setTalking, { pageSize });

export const setSubtotalsVisibility = (visible, setTalking) =>
    callPost('/api/auth/SetSubtotalsVisibility', setTalking, { visible });

export const setToolbarVisibility = (visible, setTalking) =>
    callPost('/api/auth/SetToolbarVisibility', setTalking, { visible });

export const setColumnVisibility = (phase, tableNo, fieldNo, fieldName, visible, setTalking) =>
    callPost('/api/auth/SetColumnVisibility', setTalking, { phase, tableNo, fieldNo, fieldName, visible });

export const scanBarcode = (barcode, component, setTalking) =>
    callPost('/api/auth/ScanBarcode', setTalking, { barcode, component });
export const scanReplyBoolean = (barcode, component, level, booleanInput, setTalking) =>
    callPost('/api/auth/ScanBarcode', setTalking, { barcode, component, level, booleanInput });
export const scanReplyDecimal = (barcode, component, level, decimalInput, setTalking) =>
    callPost('/api/auth/ScanBarcode', setTalking, { barcode, component, level, decimalInput });
export const scanReplyTwoDecimals = (barcode, component, level, decimalInput, decimalInput2, setTalking) =>
    callPost('/api/auth/ScanBarcode', setTalking, { barcode, component, level, decimalInput, decimalInput2 });
export const scanReplyText = (barcode, component, level, textInput, setTalking) =>
    callPost('/api/auth/ScanBarcode', setTalking, { barcode, component, level, textInput });
export const cancelScanBarcode = () =>
    cancelRequest('/api/auth/ScanBarcode');

export const setLineSelection = (barcode, selected, setTalking) =>
    callPost('/api/auth/SetLineSelection', setTalking, { barcode, selected });

export const resetSelection = (setTalking) =>
    callPost('/api/auth/ResetSelection', setTalking, {});

export const preparePdf = (phase, setTalking) =>
    callPost('/api/auth/PreparePdf', setTalking, { phase });
export const cancelPreparePdf = () =>
    cancelRequest('/api/auth/PreparePdf');

export const getSheetBarcodeComponents = (setTalking) =>
    callPost('/api/auth/GetSheetBarcodeComponents', setTalking, {});

export const setPreferredSheetComponent = (component, setTalking) =>
    callPost('/api/auth/SetPreferredSheetComponent', setTalking, { component });

export const getGridSetup = (phase, setTalking) =>
    callPost('/api/auth/GetGridSetup', setTalking, { phase });
export const cancelGetGridSetup = () =>
    cancelRequest('/api/auth/GetGridSetup');

export const getGridDefaults = (phase, setTalking) =>
    callPost('/api/auth/GetGridDefaults', setTalking, { phase });

export const setColumnsOrdered = (isModified, phase, data, setTalking) =>
    !isModified ? Promise.resolve() : callPost('/api/auth/SetColumnsOrdered', setTalking, { phase, data });

export const setSortingColumns = (isModified, phase, data, setTalking) =>
    !isModified ? Promise.resolve() : callPost('/api/auth/SetSortingColumns', setTalking, { phase, data });

export const setCoSelect = (isModified, phase, data, setTalking) =>
    !isModified ? Promise.resolve() : callPost('/api/auth/SetCoSelect', setTalking, { phase, data });

export const changePasswordOnLogin = (employee, oldPassword, password, setTalking) =>
    callPost('/api/ChangePasswordOnLogin', setTalking, { employee, oldPassword, password });

export const verifyNew = (employee, otpToken, otpMethod, setTalking) =>
    callPost('/api/VerifyNew', setTalking, { employee, otpToken, otpMethod });

export const sendTokenNew = (employee, setTalking) =>
    callPost('/api/SendTokenNew', setTalking, { employee });

export const setupVerification = (employee, setTalking) =>
    callPost('/api/SetupVerification', setTalking, { employee });
export const cancelSetupVerification = () =>
    cancelRequest('/api/SetupVerification');

export const forgotPassword = (employee, setTalking) =>
    callPost('/api/ForgotPassword', setTalking, { employee });
