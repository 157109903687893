import { useState, useCallback, useRef, useEffect, useMemo } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { AppBar, Box, Toolbar, Typography, Container, IconButton, Tabs, Tab, useMediaQuery } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoffIcon from '@mui/icons-material/Logout';
import { useDebounce } from './helper/useDebounce';

export default function NavMenu({ appUser, phase, setPhase, isTalking, setForceRefresh, lastRefresh, doLogoff, gridSetupModified }) {
    const vwSmUp = useMediaQuery((theme) => theme.breakpoints.up('sm'));
    const vwMdUp = useMediaQuery((theme) => theme.breakpoints.up('md'));
    const [toolbarHeight, setToolbarHeight] = useState(0);
    const debounce = useDebounce();
    const refToolbar = useRef();
    const refToolbarCb = useCallback(node => {
        refToolbar.current = node;
        if (node) {
            setToolbarHeight(node.offsetHeight);
        }
    }, []);
    let location = useLocation();
    let navigate = useNavigate();

    const handleResize = useCallback(() => {
        if (refToolbar.current)
            setToolbarHeight(refToolbar.current.offsetHeight);
    }, []);

    useEffect(() => {
        const debouncedHandleResize = debounce(handleResize, 300);
        window.addEventListener('resize', debouncedHandleResize)
        return () => { window.removeEventListener('resize', debouncedHandleResize); }
    }, [handleResize, debounce]);

    const currentPathIsTasklist = useMemo(() =>
        new String('/tasklist').localeCompare(location.pathname, undefined, { sensitivity: 'base' }) === 0, [location.pathname]);
    const currentPathIsGridSetup = useMemo(() =>
        new String('/gridsetup').localeCompare(location.pathname, undefined, { sensitivity: 'base' }) === 0, [location.pathname]);
    const currentPathIsScanPage = useMemo(() =>
        new String('/scan').localeCompare(location.pathname, undefined, { sensitivity: 'base' }) === 0, [location.pathname]);
    
    return (<>
        <AppBar sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
            <Container maxWidth={false}>
                <Toolbar ref={refToolbarCb}>
                    <Typography
                        variant="h6"
                        component='a'
                        href='/'
                        noWrap
                        sx={{
                            mr: 2,
                            display: 'flex',
                            flexGrow: 1,
                            color: 'inherit',
                            textDecoration: 'none'
                        }}
                    >
                        {vwSmUp ? 'Designstar etiketopdracht' : 'Designstar'}
                    </Typography>
                    {appUser.isLoggedIn && (
                        <>
                            <Typography variant='caption' sx={{ mr: 2 }}>
                                {vwMdUp ? `${appUser.user} - ${appUser.name}, ${appUser.vendorName}` : appUser.user}<br />
                                {currentPathIsTasklist && (
                                    <span onClick={() => { setForceRefresh(prev => prev + 1) }} style={{ cursor: 'pointer' }}>
                                        {`Laatst ververst: ${lastRefresh ? lastRefresh.toLocaleTimeString() : ''}`}
                                    </span>
                                )}
                            </Typography>

                            <Box sx={{ flexGrow: 0 }}>
                                <IconButton LinkComponent={RouterLink} to='/profile' disabled={isTalking} size={vwSmUp ? 'large' : 'small'} sx={{ color: 'common.white', '&.MuiButtonBase-root.Mui-disabled.MuiIconButton-root.Mui-disabled': { color: 'grey.300' } }}>
                                    <AccountCircleIcon titleAccess={appUser.name} />
                                </IconButton>
                                <IconButton onClick={doLogoff} disabled={isTalking} edge='end' size={vwSmUp ? 'large' : 'small'} sx={{ color: 'common.white', '&.MuiButtonBase-root.Mui-disabled.MuiIconButton-root.Mui-disabled': { color: 'grey.300' } }}>
                                    <LogoffIcon titleAccess={'Uitloggen'} />
                                </IconButton>
                            </Box>
                        </>
                    )}
                </Toolbar>
            </Container>
        </AppBar>
        <Toolbar />
        {appUser.isLoggedIn && appUser.phaseOptions.length > 1 && !currentPathIsScanPage && (<>
            <Box component='nav' position='fixed' width='100%' top={toolbarHeight} left={0}
                sx={{ backgroundColor: 'background.paper', zIndex: (theme) => theme.zIndex.drawer + 1, borderBottom: 1, borderColor: 'secondary.light' }} >
                <Container maxWidth={false} >
                    <Toolbar disableGutters variant='dense' >
                        <Tabs
                            value={(currentPathIsTasklist || currentPathIsGridSetup) && phase ? phase : false}
                            centered
                            sx={{ flexGrow: 1, }}
                        >
                            {appUser.phaseOptions.map((ph) => (
                                <Tab
                                    key={ph}
                                    label={ph}
                                    value={ph}
                                    onClick={() => {
                                        setPhase(ph);
                                        if (!currentPathIsTasklist && !currentPathIsGridSetup) {
                                            navigate('/tasklist');
                                        }
                                    }}
                                    disabled={isTalking || (currentPathIsGridSetup && gridSetupModified)}
                                />
                            ))}
                        </Tabs>
                    </Toolbar>
                </Container>
            </Box>
            <Toolbar variant='dense' />
        </>)}
    </>);
}
NavMenu.propTypes = {
    appUser: PropTypes.exact({
        user: PropTypes.string,
        name: PropTypes.string,
        isLoggedIn: PropTypes.bool,
        vendor: PropTypes.string,
        vendorName: PropTypes.string,
        phaseOptions: PropTypes.arrayOf(PropTypes.string)
    }),
    phase: PropTypes.string,
    setPhase: PropTypes.func,
    isTalking: PropTypes.bool,
    setForceRefresh: PropTypes.func,
    lastRefresh: PropTypes.instanceOf(Date),
    doLogoff: PropTypes.func,
    gridSetupModified: PropTypes.bool
}
